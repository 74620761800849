:root {
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #FF574A;
  --bs-secondary: #3D2F68;
  --bs-light: #f7f8f9;
  --bs-dark: #343a40;
  --bs-primary-rgb: 255, 87, 74;
  --bs-secondary-rgb: 61, 47, 104;
  --bs-light-rgb: 247, 248, 249;
  --bs-dark-rgb: 52, 58, 64;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-rgb: 143, 147, 151;
  --bs-body-font-family: Roboto, sans-serif;
  --bs-body-font-size: 0.875rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.6;
  --bs-body-color: #8f9397;
  --bs-body-bg: #ffffff;
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
  --color-1-purple: #3D2F68;
  --color-2-pink: #F8275B;
  --color-3-orange: #FF574A;
  --color-4-salmon: #FF737D;
  --color-5-yellow: #FFD700;
  }

  @font-face {
    font-family: 'Quicksand';
    src: url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
  }

  * {
    box-sizing: border-box;
}

/* TYPOGRAPHY */

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.2;

}

h1, .h1 {
    font-size: calc(1.11875rem + 1.425vw);
    text-transform: capitalize;
}

h2, .h2 {
    font-size: calc(1.075rem + 0.9vw);
    text-transform: capitalize;
}

h3, .h3 {
    font-size: calc(1.053125rem + 0.6375vw);
    text-transform: capitalize;
}

h4, .h4 {
    font-size: calc(1.03125rem + 0.375vw);

}

h5, .h5 {
    font-size: calc(1.009375rem + 0.1125vw);

}

h6 .h6 {
    font-size: 0.875rem;

}

p {
    margin-top: 0;
    margin-bottom: 0.8rem;
}

.p-large {
  font-size: 12px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

figure {
  margin: 0 0 1rem;
}


.link-container {
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.fs-xs {
  font-size: 10px;
}

/* TEXT AND FONTS */

.text-primary {
  color: rgb(var(--bs-primary-rgb)) !important;
}

.text-grad {
  background: -webkit-linear-gradient(160deg, rgba(61,47,104,1) 0%, rgba(248,39,91,1) 15%, rgba(255,87,74,1) 30%, rgba(255,215,0,1) 100%);
  background: linear-gradient(135deg, rgba(61,47,104,1) 0%, rgba(248,39,91,1) 15%, rgba(255,87,74,1) 30%, rgba(255,215,0,1) 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.text-secondary {
  color: rgb(--bs-secondary-rgb) !important;
}

.text-light {
  color: rgb(--bs-light-rgb) !important;
}

.text-dark {
  color: rgb(--bs-dark-rgb) !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-white {
  color: #fff !important;
}

.text-pink {
  color: #F8275B !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.title {
  padding: 0 0 10px !important;
}

.title .pre-title {
  font-size: 18px;
  font-style: thin;
}

.title h2 {
  font-size: 32px;
  font-weight: 400;
  margin-bottom: 10px;
}

.title .text-center p {
  padding: 0 80px;
}

.form-control:focus {
  border-color: #ccc;
  box-shadow: none;
}

.text-center {
  text-align: center;
}

.italic {
  font-style: italic;
}

.quicksand-font {
  font-family: 'Quicksand', serif !important;
}

.hover-underline:hover {
  text-decoration: underline;
}

/* BACKGROUND COLOURS */

.bg-primary {
  background-color: rgb(var(--bs-primary-rgb)) !important;
}

.bg-grad {
  background: linear-gradient(140deg, rgba(61,47,104,1) 0%, rgba(248,39,91,1) 20%, rgba(255,87,74,1) 30%, rgba(255,215,0,1) 100%) !important;
}

.bg-dark-grad {
  background: linear-gradient(150deg, #343a40 0%, #626d78 100%) !important;
}

.bg-grad-basic {
  background: linear-gradient(150deg, #F8275B 0%, #FFD700 100%) !important;
}

.bg-secondary {
  background-color: rgb(var(--bs-secondary-rgb)) !important;
}

.bg-light {
  background-color: rgb(var(--bs-light-rgb)) !important;
}

.bg-dark {
  background-color: rgba(var(--bs-dark-rgb)) !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.fibre-bg {
  background: url(../images/fibre-bg-img.png);
  background-size: cover;
  background-position: center center;
}

.wireless-bg {
  background: url(../images/wireless-bg-img.png);
  background-size: cover;
  background-position: center center;
}

.hyperfibre-bg {
  background: url(../images/hyperfibre-bg-img.png);
  background-size: cover;
  background-position: center center;
}

.internet-collage-bg {
  background: url(../images/internet-collage.png);
  background-size: cover;
  background-position: center center;
}

.pattern-overlay-4 {
  position: relative;
  z-index: 1;
}

.pattern-overlay-4::before {
  content: "";
  background: url(../images/overlay-4.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
} 

/* BORDERS */


.border-primary {
  border-color: rgb(var(--bs-primary-rgb)) !important;
  border-width: 0.75px !important;
  border-style: solid;
}

.border-secondary {
  border-color: rgb(255, 215, 0) !important;
  border-width: 0.75px !important;
  border-style: solid;
}

.border-muted {
  border-color: #ccc;
  border-width: 0.75px !important;
  border-style: solid;
}

.border-none {
  border: none !important;
}

.border-right {
  border-right: 1px solid #ccc;
}

/* BUTTONS */

.btn {
  letter-spacing: 0.5px;
  position: relative;
  overflow: hidden;
  outline: 0;
  white-space: nowrap;
  line-height: 1.5;
  transition: all 0.3s ease-in-out;
  cursor: pointer !important;
}

.btn-primary {
  color: #fff;
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);
  border: 0;
}

.btn-primary:hover, .btn-primary:active {
  color: #fff;
  background-color: #cc463b;
  border-color: #cc463b;
}

.btn-grad {
  color: #fff;
  background: linear-gradient(120deg, var(--color-1-purple), var(--color-2-pink) 31%, var(--color-5-yellow)) var(--x, 0)/200%;
  transition: all 0.5s linear;
  border: 0;
}

.btn-grad:hover, .btn-grad:active {
  color: #fff !important;
  --x: 100%;
}

.btn-grad:disabled {
  color: rgba(255, 255, 255, 0.6);

}

.btn-grad-disabled {
  color: #fff;
  background: linear-gradient(120deg, var(--color-1-purple), var(--color-2-pink) 31%, var(--color-5-yellow)) var(--x, 0)/200%;
  transition: all 0.5s linear;
  border: 0;
  opacity: 0.4;
}

.btn-grad-disabled:hover, .btn-grad-disabled:active {
  color: #fff;
  opacity: 0.4;
}

.btn-disabled {
  color: #fff;
  background-color: rgba(248,39,91, 0.5);
  border: 0;
}

.btn-disabled:hover, .btn-disabled:active {
  color: #fff;
  background-color: rgba(248,39,91, 0.5);
  border: 0;
}

.btn-dark {
  color: #fff;
  background-color: rgb(var(--bs-dark-rgb)) !important;
  border-color: rgb(var(--bs-dark-rgb)) !important;
  cursor: pointer;
}

.btn-dark:hover, .btn-dark:active {
  color: rgb(var(--bs-primary-rgb)) !important;
  background-color: rgba(var(--bs-light-rgb), 0.8) !important;
  border-color: rgba(var(--bs-light-rgb), 0.8) !important;
}

.btn-dark-opaque {
  color: #fff;
  background-color: rgba(var(--bs-dark-rgb), 0.5) !important;
  border-color: rgba(var(--bs-dark-rgb), 0.2) !important;
  cursor: pointer;
}

.btn-dark-opaque:hover, .btn-dark-opaque:active {
  color: #fff;
  background-color: rgba(var(--bs-dark-rgb), 0.8) !important;
  border-color: rgba(var(--bs-dark-rgb), 0.2) !important;
  cursor: pointer;
}

.btn-white {
  color: #F8275B !important;
  background-color: rgba(255,255,255,0.8) !important;
  border-color: rgba(255,255,255,0.8) !important;
  cursor: pointer;
}

.btn-white:hover, .btn-white:active {
  color: #FF737D !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

.btn-white-outline {
  color: #fff !important;
  background-color: transparent;
  border-radius: 5px !important;
  border: 1px solid #fff !important;
  border-color: #fff !important;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  transition: all 0.4s ease-in-out;
  cursor: pointer;
}

.btn-white-outline:hover, .btn-white-outline:active {
  color: #F8275B !important;
  background-color: #fff !important;
  border-color: #F8275B !important;
}

.btn-primary-outline {
  color: #FF574A  !important;
  background-color: transparent;
  border-color: #FF574A ;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
}

.btn-primary-outline:hover, .btn-primary-outline:active {
  color: #fff !important;
  background-color: #FF574A  !important;
  border-color: #FF574A  !important;
}

.btn-light {
  background-color: #adb5bd;
}


.btn-trash {
  color: #8f9397;
  transition: all 0.25s ease-in-out;
  cursor: pointer;
}

.btn-trash:hover, .btn-trash:active {
  color: rgb(var(--bs-dark-rgb));
}

.btn-none {
  border: none !important;
  padding: 0 !important;
  margin: 0 !important;
}

.btn-xs {
  padding: 0.4rem 0.6rem;
  font-size: 0.6rem;
  line-height: 1.2;
  border-radius: 3px;
}

.btn-sm {
  padding: 0.4rem 0.8rem;
  font-size: 0.7875rem;
  border-radius: 3px;
}

.btn-lg {
  padding: 0.8rem 1.5rem;
  font-size: calc(1.009375rem + 0.1125vw);
  border-radius: 3px;
}

.btn-block {
  width: 100%;
}

.loadingIcon {
  animation: spin infinite 2s linear;
}

.icon-large {
  font-size: 150px;
  margin-top: 20px;
  right: 20px;
  bottom: 10px;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.highlight:hover {
  background-color: rgba(255,87,74, 0.1);
  color: rgba(255,87,74,1);
}

.capitalize {
  text-transform: capitalize;
}

/* DROPDOWNS */

#userDropdown .dropdown-toggle {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: capitalize;
  white-space: nowrap;
}

.dropdown-toggle:after {
  font-size: 0.6rem;
  font-weight: 900;
  border: none !important;
  content: "\f078" !important;
  font-family: "Font Awesome 5 Free" !important;
  margin-left: 0.35rem;
}

.dropdown-toggle::after {
  display: inline-block;
}

#userDropdown .dropdown-menu {
  min-width: 12rem;
  padding: 1rem 0;
  margin: 0;
  font-size: 0.875rem;
  color: #8f9397;
  text-align: left;
  list-style: none;
  border: 0 solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

#userDropdown .dropdown-menu:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  height: 3px;
  opacity: 1;
  background: linear-gradient(150deg, #3D2F68 0%, #F8275B 40%, #FFD700 100%);
  transition: all 0.5s ease-in-out;
}

#userDropdown .dropdown-item {
  font-weight: 400;
  text-transform: capitalize;
  position: relative;
  padding: 0.4rem 1rem;
  transition:  all 0.3s ease-in-out;
}

#userDropdown .dropdown-item:hover {
  color: #F8275B !important;
  background-color: transparent;
}

/* SIZE & POSITION */

.h-100 {
  height: 100% !important;
}

.h-200 {
  height: 200px !important;
}

.h-400 {
  height: 400px !important;
}

.h-500 {
  height: 500px !important;
}

.w-100 {
  width: 100% !important;
}

.w-90 {
  width: 90% !important;
}

.w-80 {
  width: 80% !important;
}

.w-70 {
  width: 70% !important;
}

.w-50 {
  width: 50% !important;
}

.w-40 {
  width: 40% !important;
}

.w-30 {
  width: 30% !important;
}

.w-20 {
  width: 20% !important;
}

.w-10 {
  width: 10% !important;
}

.xs-img {
  max-width: 40% !important;
}

.small-img {
  max-width: 70% !important;
}

.md-img {
  width: 80% !important;
}

.std-img {
  object-fit: cover;
  height: auto !important;
  max-width: 100%;
}

.center-block {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.align-self-center {
  align-self: center !important;
}

.mt-md-0 {
  margin-top: 0 !important;
}

.trasnlate-middle-x {
  transform: translateX(-50%) !important;
}

.start-50 {
  left: 50% !important;
}

.end-0 {
  right: 0 !important;
}

.top-0 {
  top: 0 !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.d-md-block {
  display: block !important;
}

.full-page {
  height: 100vh;
  width: 100% !important;
}

/* PADDING & MARGIN */

.p-0 {
  padding: 0 !important;
}

.pt-6 {
  padding-top: 4.5rem !important;
}

.mb-n9 {
  margin-bottom: -11rem !important;
}

.mt-30 {
  margin-top: 1.875rem;
}

/* GRID & FLEX */

.container {
  padding: 0 15px;
}

/* TABS */

.nav-tabs .tab-line {
  border-bottom: solid 2px rgb(61,47,104) !important;
}

.nav-tabs .tab-line .nav-item {
  margin-bottom: -2px;
}

.nav-tabs .tab-line .nav-item:first-child .nav-link {
  padding-left: 0;
}

.nav-link {
  transition: color 0.35s ease-in-out, background-color 0.35s ease-in-out, border-color 0.35s ease-in-out !important;
}

.nav-tabs .tab-line .nav-link {
  background: transparent;
  border-bottom: 2px solid transparent;
}

.nav-tabs .tab-line .nav-link,
.nav-tabs .tab-line .nav-link:focus {
  border: transparent !important;
}

.nav-tabs .nav-item .nav-link {
  color: #343a40;
  padding: 0.5rem 1rem;
  border-bottom: transparent !important;
}

.nav-tabs .nav-link {
  margin-bottom: -1px;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  border-bottom: transparent !important;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-link:focus {
  border: transparent !important;
  background: transparent !important;
  color: rgba(248,39,91,1) !important;
  border-bottom: 3px solid rgba(248,39,91,1) !important;
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
}

.tab-content .tab-pane .active {
  color: #000 !important;
}

/* HEADER NAVBAR */

.navbar-transparent {
  background: transparent !important;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.navbar {
  z-index: 199;
}

.navbar-brand {
  font-family: 'Quicksand', sans-serif !important;
  font-size: 30px;
  font-weight: 700;
  font-optical-sizing: auto;
  font-style: normal;
}

.navbar-brand-item {
  width: 50px;
  margin-right: 10px;
  margin-top: -10px;
}

.header-link {
  color: #fff !important;
}

.header-link:hover {
  color: #FFD700 !important;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  color: #fff !important;
  outline: none;
  border: none;
  box-shadow: none;
}

.navbar-light span.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='white' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  color: white;
}

@media (max-width: 990px) {
  #responsive-navbar-nav {
    background-color: rgba(255,255,255,0.8) !important;
    padding: 0 !important;
    padding-bottom: 8px !important;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
    margin: 10px;
    text-align: center;
  }

  .navbar {
    text-align: end !important;
  }

  .header-link {
    color: #000 !important;
  }

  .header-link:hover {
    color: #FF574A !important;
  }

  .header-link .btn {
    background-color: #FF574A !important;
    border-color: transparent !important;
  }

  .header-link .btn:hover {
    color: #FFD700 !important;
  }

}

/* MAIN BANNER */

.pattern-overlay-1 {
  z-index: 1;
}

.pattern-overlay-1::before {
  content: "";
  background: url(../images/pattern-bg-overlay.png);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1;
}

#signUpButton {
  z-index: 100;
}

.z-index-100 {
  z-index: 100;
}

.clipped {
  clip-path: url(#blob);
}

/* WAVE ANIMATION */

.wave-animation {
  width: 100%;
  background: red;
  overflow: hidden;
}

.wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100px;
  background: url(../images/wave.png);
  background-size: 1000px 100px;
  z-index: 400;
}

.wave1 {
  animation: animate 30s linear infinite;
  z-index: 1000;
  opacity: 0.8;
  animation-delay: 0s;
  bottom: 0;
}

.wave2 {
  animation: animate2 15s linear infinite;
  z-index: 999;
  opacity: 0.5;
  animation-delay: -5s;
  bottom: 10px;
}

.wave3 {
  animation: animate2 15s linear infinite;
  z-index: 998;
  opacity: 0.6;
  animation-delay: -2s;
  bottom: 15px;
}

.wave4 {
  animation: animate2 15s linear infinite;
  z-index: 997;
  opacity: 1;
  animation-delay: -2s;
  bottom: 0px;
}

/* ALERTS */

.alert {
  position:relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-primary {
  color: rgb(61,47,104);
  background-color: rgba(61,47,104,0.2);
  border-color: rgba(61,47,104,0.3)
}

.alert-secondary {
  color: rgb(186, 161, 21);
  background-color: rgba(255,215,0,0.2);
  border-color: rgba(255,215,0,0.6);
}

.alert-link-secondary {
  color: rgb(186, 161, 21);
}

.alert-error {
  color: rgba(248,39,91,1);
  background-color: rgba(248,39,91,0.2);
  border-color: rgba(248,39,91,0.3);
}

/* PLAN CARD */

.planCard ul {
  margin-block-start: 1em;
  margin-block-end: 1em;
  padding-inline-start: 20px;
}

.planCard .list-group-borderless .list-group-item {
  border: none !important;
  color: #8f9397 !important;
  padding: 0.425rem 0 !important;
}

.planCard .list-group .list-group-item {
  background: transparent;
}

.planCard .list-group .list-group-item i {
  margin-right: 5px;
  font-weight: 900;
}

.planCard span {
  font-weight: 600;
}

/* FEATURE BOX */


.f-style-1 {
  padding: 20px;
}

.feature-box.f-style-1.f-style-1.active {
  box-shadow: 0px 0px 40px rgba(83, 88, 93, 0.1);
}


#orderSummary {
  position: fixed !important;
  width: 100%;
}

.feature-box {
  position: relative;
  overflow: hidden;
  transition: all 0.5s ease-in-out;
}

.feature-box.f-style-2 {
  padding: 20px;
  box-shadow: 0px 0px 40px rgba(83, 88, 93, 0.1);
  border-radius: 5px;
}

.feature-box.f-style-3 {
  padding: 20px;
  box-shadow: 0px 0px 40px rgba(83, 88, 93, 0.1);
  border-radius: 5px;
}

.feature-box.f-style-2:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  height: 5px;
  border-radius: 5px;
  visibility: hidden;
  opacity: 0;
  background: linear-gradient(150deg, #3D2F68 0%, #F8275B 40%, #FFD700 100%);
  transition: all 0.5s ease-in-out;
}

.feature-box.f-style-3:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: block;
  height: 5px;
  border-radius: 5px;
  visibility: visible;
  opacity: 1;
  background: linear-gradient(150deg, #3D2F68 0%, #F8275B 40%, #FFD700 100%);
  transition: all 0.5s ease-in-out;
}

.feature-box.f-style-2:hover.f-style-2:before {
  opacity: 1;
  visibility: visible;
}

.feature-box.f-style-2:hover {
  box-shadow: 0px 0px 40px rgba(83, 88, 93, 0.2);
}

.feature-box.f-style-3:hover {
  box-shadow: 0px 0px 40px rgba(83, 88, 93, 0.2);
}

.feature-box.f-style-2 .feature-box-icon {
  font-size: 72px;
  margin-left: -45px;
  float: left;
  margin-right: 30px;
  padding-top: 2px;
  transition: all 0.5s ease-in-out;
}

.feature-box.f-style-3 .feature-box-icon {
  font-size: 60px;
  margin-left: -10px;
  float: left;
  padding-top: 2px;
  transition: all 0.5s ease-in-out;
}

.feature-box.f-style-2:hover .feature-box-icon {
  transform: translateX(10px);
}

.feature-box .feature-box-icon {
  padding: 20px;
  line-height: 24px;
}

.icon-grad i:before {
  background: linear-gradient(170deg, #3D2F68 10%, #F8275B 40%, #FFD700 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.feature-box.f-style-2 .feature-box-title {
  margin-top: 20px;
}

.feature-box.f-style-3 .feature-box-title {
  margin-top: 10px;
  font-size: 26px;
}

.feature-box .feature-box-title {
  color: #343a40;
  font-size: 40px;
  font-weight: 500;
  line-height: 34px;
  margin-bottom: 10px;
}


/* FORMS */

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}

.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input:checked {
  background: #F8275B;
  border-color: #F8275B;
}

.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-input:focus {
  border-color: #ccc;
  box-shadow: none;
}

/* TIMELINE */

.timeline-line:before {
  content: "";
  position: absolute;
  width: 2px;
  background: #343a40;
  height: 100%;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.timeline-dot {
  height: 30px;
  width: 30px;
  display: block;
  border: solid 2px #343a40;
  background: #ffffff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translate3d(0, -50%, 0);
  left: 0;
  right: 0;
  margin: 0 auto;
}

/* ACCORDION */

.accordion .accordion-line .accordion-item:first-child {
  border-top: 2px solid rgba(0,0,0,0.1);
  border-radius: 0;
}

.accordion.accordion-line .accordion-item {
  border: none;
  border-bottom: 2px solid rgba(0,0,0,0.1);
  margin-bottom: 0px;
  border-radius: 0;
}

.accordion-item {
  background-color: #fff !important;
}

.accordion.accordion-line .accordion-header {
  position: relative;
}

.accordion-header {
  margin-bottom: 0;
  border-radius: 0;
  border: none !important;
}

.accordion.accordion-line .accordion-header .accordion-button {
  background-color: transparent;
  color: #343a40;
  border: none;
  padding-left: 55px;
  padding-right: 15px;
  border-radius: 0;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.accordion .accordion-button {
  text-align: left;
  margin-bottom: 0;
  border: none;
}

.button:not(:disabled), [type=button]:not(:disabled) {
  cursor: pointer;
}

.accordion-button {
  position: relative;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 0.875rem;
  border-radius: 0px;
  border: none !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
}

.accordion.accordion-line .accordion-header .accordion-button:before {
  content: "\f056";
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 50%;
  font-weight: bolder;
  font-size: 24px;
  left: 8px;
  color: #F8275B;
  padding: 10px;
  width: 26px;
  height: 26px;
  line-height: 7px;
  text-align: center;
  transform: translateY(-50%);
  transition: color 0.15s ease-in-out;
}

.accordion.accordion-line .accordion-header .accordion-button.collapsed:before {
  content: "\f055";
  color: #8f9397;
}

.accordion-button::after {
  background-image: initial;
  width:0;
}

.accordion-button:not(.collapsed)::after {
  background-image: initial;
  width: 0;
}

.accordion-body {
  padding-left: 55px !important;
  border: none !important;
}

/* PROCESS */

.process-border {
  position: relative;
  max-width: 100%;
  z-index: 1;
}

.process-number {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  line-height: 50px;
  color: #fff;
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
}

.process-advance .border-end {
  border-right: 1px solid rgba(0,0,0,0.1);
}

.process-advance .border-start {
  border-left: 1px solid rgba(0,0,0,0.1);
}

.process-advance .border-end:after {
  content: '';
  position: absolute;
  top: 50%;
  display: block;
  width: calc(50% + 30px);
  border-top-width: 1px;
  border-top-style: solid;
  margin-top: -1px;
  right: -30px;
  border-color: #dfe2e5 !important;
  z-index: -1;
}

.process-advance .border-start:before {
  content: '';
  position: absolute;
  top: 50%;
  display: block;
  width: calc(50% + 30px);
  border-top-width: 1px;
  border-top-style: solid;
  margin-top: -1px;
  left: -30px;
  border-color: #dfe2e5 !important;
  z-index: -1;
}

/* TABS */

#planTabs .nav-link,
#hardwareTabs .nav-link {
  color: #6c757d;
}

#planTabs .nav-link.active {
  border-bottom: 3px solid !important;
  border-image: linear-gradient(to right, #3D2F68 0%, #F8275B 20%, #FFD700 100%) 1 !important;
  color: #F8275B !important;
  transition: all 0.2s ease-in-out !important;
}

#planTabs .nav-link:hover {
  color: #F8275B !important;
  border: transparent;
  border-bottom: 3px solid #F8275B!important;
}

#hardwareTabs .nav-link:hover {
  color: #3D2F68 !important;
  border: transparent;
  border-bottom: 3px solid #3D2F68 !important;
}

/* SERVICE DESCRIPTIONS */

.service-desc .list-group {
  display: flex;
}

.service-desc ul {
  list-style-position: inside;
  padding: 0;
  margin: 0;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

.service-desc .list-group-icon-primary-bg .list-group-item {
  display: flex;
}

.service-desc .list-group-borderless .list-group-item {
  border: none;
  color: #8f9397;
  padding: 0.425rem 0;
}

.service-desc .list-group .list-group-item {
  background: transparent;
}

.service-desc .list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}

.service-desc .list-group-item {
  position: relative;
}

.service-desc .list-group-icon-primary-bg i {
  color: #F8275B;
  font-weight: 600;
  height: 22px;
  width: 22px;
  line-height: 23px;
  text-align: center;
  margin-right: 10px !important;
  font-size: 20px;
}

/* DATA LABELS */

.collapsed>[data-label-collapsed]:before, [data-label-collapsed].collapsed:before {
  content: attr(data-label-collapsed);
}

[data-label-collapsed]:before {
  content: attr(data-label-expanded);
}

/* LIST STYLES */

.list-group-borderless .list-group-item {
  border: none;
  color: #8f9397;
  padding: 0.425rem 0;
}

.list-group .list-group-item i {
  margin-right: 8px;
}

/* COUNTER */

.counter-group {
  background-color: #fff;
  border: 1px solid #F8275B;
  border-radius: 5px;
  display: inline-flex;
  overflow: hidden;
  transform: translateZ(0);
  color: #343a40;
}

.counter-group-light {
  border: 1px solid #adb5bd;
}

.input-group-btn button {
  transition: color 0.2s ease-in-out;
  color: #343a40 !important;
}

.input-group-btn:hover button {
  color: #F8275B !important;
}

.input-group-btn button:active,
.input-group-btn button:focus {
  border: transparent !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.count-input .btn:not(.btn-primary):hover {
  color: #000;
  background-color: #adb5bd;
}

.count-input .btn {
  border: 0;
  border-radius: 0;
}

.btn.disabled, .btn:disabled, fieldset:disabled .btn {
  opacity: 0.5;
  pointer-events: none;
}

.btn-icon {
  flex-shrink: 0;
  height: 3rem;
  padding: 0;
  width: 3rem;
}

.count-input .btn-group-lg>btn+.form-control, 
.count-input .btn-lg+.form-control {
  width: 3rem;
}


.counter-group .form-control {
  appearance: textfield;
  background-color: transparent;
  border: 0;
  border: none !important;
  border-radius: 0;
  font-weight: 500;
  padding: 0 .25rem;
  text-align: center;
}

.count-input .form-control-lg {
  font-size: 1rem;
  min-height: calc(1.5em + 1.37rem + 2);
  padding: .685rem 1.125rem;
}

.count-input .form-control {
  background-clip: padding-box;
  color: #343a40;
  line-height: 1.5;
}

.counter-group .input-number {
  color: #343a40 !important;
}

/* FORMS */

.form-control {
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  border: 2px solid #dfe2e5;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.textarea {
  height: 150px;
  box-sizing: border-box;
  font-size: 16px;
  text-wrap: wrap !important;
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  white-space: normal;
  flex-wrap: wrap !important;
  flex: 1 !important;
}

/* TABLE */

td {
  padding: 0 !important;
}

.table>thead {
  vertical-align: bottom !important;
}

tbody, td, tfoot, th, thead, tr {
  border: 0 solid;
  border-color: inherit;
}

.table thead th div nav {
  line-height: 1.375rem !important;
  flex-direction: row !important;
  gap: 0 !important;
  display: flex !important;
  padding: 0.5rem 1.25rem;

}

/* PROGRESS BAR */

.progress-bar-indicator {
  height: 100%; 
  background-image: linear-gradient(to right, red, green, blue);
  /*                ↓ same as container width */  
  background-size: 500px 100%;
  border-radius: 25px;
}

#indicator1 {
  width: 33%;
}

#indicator2 {
  width: 66%;
}

#indicator3 {
  width: 100%;
}

#checkoutSteps .nav-pills {
  background: transparent !important;
  border-radius: 50px;
}

#checkoutSteps .nav-pills a {
  color: #FF574A !important;
}

#checkoutSteps .nav-pills .active {
  background: linear-gradient(120deg, var(--color-1-purple), var(--color-2-pink) 31%, var(--color-5-yellow)) var(--x, 0)/200%;
  border-radius: 50px;
}

#checkoutSteps .nav-link.active {
  color: #fff !important;
}

#checkoutSteps .nav {
  border-radius: 50px;
  border: 1px solid #FF574A;
}

#checkoutSteps {
  border-radius: 50px;
}

/* NAV TABS */

.nav-pills .nav-link {
  color: #212529 !important;
  background-color: transparent !important;
}

.nav-pills .nav-link:hover {
  background-color: rgba(var(--bs-primary-rgb), 0.3)!important;
  color: #FF574A !important;
}

.nav-pills .nav-link.active {
  background-color: #fff !important;
  color: #212529 !important;
}

.nav-pills .nav-link.active:hover {
  background-color: rgba(var(--bs-primary-rgb), 0.3)!important;
  color: #FF574A !important;
}

/* FOOTER */

.footer .widget {
  position: relative;
  margin-bottom: 20px;
}

footer .footer-logo {
  font-family: 'Quicksand', sans-serif !important;
  font-size: 30px;
  font-weight: 700;
  text-decoration: none;
}

footer .footer-logo img, footer .footer-logo-item {
  height: 50px;
  text-align: left;
  max-width: 100%;
  width: auto;
  margin-right: 10px;
}

footer .footer-logo img {
  vertical-align: middle !important;
}

footer .nav-item a {
  color: #6c757d !important;
  padding: 0;
}

footer hr {
  color: #8f9397;
  width: 100%;
  margin: 0px auto;
  text-align: center;
  overflow: hidden;
  line-height: 0em;
}

footer .nav-link:hover {
  color: #FF574A !important;
}

/* ANIMATIONS */

@keyframes animate {
  0%  {
    background-position-x: 0;
  }
  100% {
    background-position-x: 1000px;
  }
}

@keyframes animate2 {
  0%  {
    background-position-x: 0;
  }
  100% {
    background-position-x: -1000px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  } 
  to {
    transform: rotate(360deg);
  }
}

/* KEYFRAMES */

@media (max-width: 768px) {
 .main-btn {
  margin-top: 20px;
 }

 .process-advance .border-end:after {
  content: '';
  position: absolute;
  top: 50%;
  display: block;
  width: 100%;
  border-top-width: 1px;
  border-top-style: solid;
  margin-top: -1px;
  right: 0px;
  border-color: #dfe2e5 !important;
  z-index: -1;
}

.process-advance .border-start:before {
  content: '';
  position: absolute;
  top: 50%;
  display: block;
  width: 100%;
  border-top-width: 1px;
  border-top-style: solid;
  margin-top: -1px;
  left: 0px;
  border-color: #dfe2e5 !important;
  z-index: -1;
}

.full-page {
  height: 60vh;
  width: 100% !important;
}

#userDropdown .dropdown-toggle {
  color: #000 !important;
  font-size: 0.875rem;
  font-weight: 500;
  text-transform: capitalize;
  white-space: nowrap;
}

}

@media (min-width: 768px) {
  .h-md-600 {
    height: 600px !important;
  }
  .display-sm-none {
    display: none;
  }
}

@media (min-width: 1200px) {
  .h-xl-900 {
    height: 900px !important;
  }
}